
/**
 * Making object fit for images IE 11-friendly
 * @author Todd Miller <https://github.com/Toddses>
 */

(function ($) {
  'use strict';

  $(document).ready(initialize);

  function initialize () {
    objectFitImages();
  }
})(jQuery);
