/**
 * Handles the share button functionality on single basic posts.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function () {
  'use strict';

  // cached elements
  let $wrapper = null;
  let $share   = null;

  // event listeners
  document.addEventListener('DOMContentLoaded', initialize);

  /**
   * Just toggle the active class on the wrapper element.
   */
  function clickHandler () {
    $wrapper.classList.toggle('is-active');
  }

  /**
   * When the DOM is ready, cache all the relevant elements and set up
   * event handlers.
   */
  function initialize () {
    $share = document.getElementById('share-button');

    if ($share) {
      $wrapper = document.getElementById('share-wrapper');
      $share.addEventListener('click', clickHandler);
    }
  }
})();
