/**
 * Handles the search box and form functionality.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function ($) {
  'use strict';

  // elements to cache
  let $searchbox   = null,
      $searchicon  = null,
      $searchblock = null,
      $searchform  = null;

  // event listeners
  $(document).ready(initialize);

  /**
   * Submit the form when the user clicks or taps the icon
   */
  function handleClick () {
    $searchform.submit();
  }

  /**
   * Activate and Deactivate the search icon as the user types
   * input into the search box
   */
  function handleInput (e) {
    const val = $(e.target).val();

    if (val === '')
      $searchblock.removeClass('is-active');
    else
      $searchblock.addClass('is-active');
  }

  /**
   * When the DOM is ready, cache all the relevant elements and set up
   * event handlers.
   */
  function initialize () {
    $searchbox   = $('#block-search-form .form-text');
    $searchicon  = $('#search-icon');
    $searchblock = $('#block-search-form');
    $searchform  = $('#search-block-form');

    $searchbox.on('input', handleInput);
    $searchicon.on('click', handleClick);
  }
})(jQuery);
