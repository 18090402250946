'use strict';

/**
 * Line-Clamp polyfill.
 *
 * Note: Line clamp support in webkit browsers is finicky when the
 * element moves on the page, so we're polyfilling all browsers
 * for full, comparable support across the board.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function () {
  'use strict';

  // regex to grab the last word after a whitespace

  var regex = /\W*\s(\S)*$/;

  var $homeCards = null,
      $basicCards = null,
      cards = [];

  /**
   * Clamp again on a debounced resize so we don't crush performance
   */
  var handleResize = debounce(function () {
    for (var i = 0; i < cards.length; i++) {
      var card = cards[i];

      // if the user resized wider, we'd lose the full copy,
      // so restore the original text so we can clamp based on that
      card.copy.textContent = card.text;

      while (card.copy.offsetHeight > card.card.clientHeight) {
        card.copy.textContent = card.copy.textContent.replace(regex, '...');
      }
    }
  }, 200);

  // event listeners
  window.addEventListener('load', initialize);
  window.addEventListener('resize', handleResize);

  /**
   * On document ready, query the relevant elements and iniitialize the clamp
   * for each one
   */
  function initialize() {
    $homeCards = document.querySelectorAll('.home-card .title');
    $basicCards = document.querySelectorAll('.basic-card .title');

    for (var i = 0; i < $homeCards.length; i++) {
      var $card = $homeCards[i];
      // there should only be one element below the container
      var $copy = $card.querySelector('*');

      // store the elements and text so we can easily recalc on resize
      var card = {
        card: $card,
        copy: $copy,
        text: $copy.textContent
      };
      cards.push(card);

      // loop here to drop the last word until the text fits in the container
      while ($copy.offsetHeight > $card.clientHeight) {
        $copy.textContent = $copy.textContent.replace(regex, '...');
      }
    }

    for (var _i = 0; _i < $basicCards.length; _i++) {
      var _$card = $basicCards[_i];
      // target the a tag below the container
      var _$copy = _$card.querySelector('a');

      // store the elements and text so we can easily recalc on resize
      var _card = {
        card: _$card,
        copy: _$copy,
        text: _$copy.textContent
      };
      cards.push(_card);

      // loop here to drop the last word until the text fits in the container
      while (_$copy.offsetHeight > _$card.clientHeight) {
        _$copy.textContent = _$copy.textContent.replace(regex, '...');
      }
    }
  }

  /**
   * Feature detect function to determine if the browser supports line-clamp natively
   */
  function supportsLineClamp() {
    var $el = document.createElement('p');
    return typeof $el.style.webkitLineClamp !== 'undefined';
  }
})();

/**
 * This file represents the template for building out jQuery functionality. Simply
 * copy this file and rename to whatever the module is named (keep the .jquery.js filename,
 * so it's obvious which components are jquery based). Later it will be compiled
 * into the core javascript file and new components do not need to be added to the .info file.
 * ES6 syntax is available via the Babel transpiler, so feel free to use modern syntax
 * and features.
 *
 * Each "component" should live in its own file. This allows for separation of concerns and
 * ease of locating functionality, and is just generally good practice.
 *
 * This file will be all commented out so as not to tarnish the compiled javascript with
 * example or template code.
 *
 * Each file should begin with a header stub, just like this one, with a short description
 * of the component and an author so we know who to blame.
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

/*
(function ($) {
  'use strict';

  // add functionality here
})(jQuery);
*/

/**
 * This file represents the template for building out vanilla javascript functionality. Simply
 * copy this file and rename to whatever the module is named. Later it will be compiled
 * into the core javascript file and new components do not need to be added to the .info file.
 * ES6 syntax is available via the Babel transpiler, so feel free to use modern syntax
 * and features.
 *
 * Each "component" should live in its own file. This allows for separation of concerns and
 * ease of locating functionality, and is just generally good practice.
 *
 * This file will be all commented out so as not to tarnish the compiled javascript with
 * example or template code.
 *
 * Each file should begin with a header stub, just like this one, with a short description
 * of the component and an author so we know who to blame.
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

/*
(function () {
  'use strict';

  // add functionality here
})();
*/

/**
 * Handles all the functionality related to the custom filter blocks
 * on the basic and taxonomy listings.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function ($) {
  'use strict';

  // cached elements

  var $button = null,
      $panel = null,
      $filters = null,
      $boxes = null,
      $apply = null,
      $reset = null,
      $form = null;

  $(document).ready(initialize);

  /**
   * Send that form, yo!
   */
  function handleFiltersApply() {
    $form.submit();
  }

  /**
   * Deactivate all filter items and input checkboxes.
   */
  function handleFiltersReset() {
    $filters.removeClass('is-active');
    $boxes.prop('checked', false);
  }

  /**
   * When the DOM is ready, cache all the relevant elements and set up
   * click handlers.
   */
  function initialize() {
    $button = $('#filters-button');
    $panel = $('#filters-panel');
    $filters = $('.filter-item');
    $boxes = $('.filter-box');
    $apply = $('#filters-apply');
    $reset = $('#filters-reset');
    $form = $('#filters-form');

    $button.on('click', togglePanel);
    $filters.on('click', toggleFilterItem);
    $apply.on('click', handleFiltersApply);
    $reset.on('click', handleFiltersReset);
  }

  /**
   * Toggle the filter item class and the state of the associated input checkbox.
   */
  function toggleFilterItem(event) {
    event.stopPropagation();

    var $el = $(event.currentTarget);
    var tid = $el.data('tid');
    var $box = $('#filter-box-' + tid);

    if ($box.prop('checked')) $box.prop('checked', false);else $box.prop('checked', true);

    $el.toggleClass('is-active');
  }

  /**
   * Open and close the panel containing the filter items by toggling classes.
   */
  function togglePanel() {
    $panel.add($button).toggleClass('is-active');
  }
})(jQuery);

/**
 * Detects whether or not the user's device has touch capabilities and
 * adds a class to the main html element for easy usage by the CSS.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function () {
  'use strict';

  if ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    document.querySelector('html').classList.add('touchevents');
  } else {
    document.querySelector('html').classList.add('no-touchevents');
  }
})();

/**
 * Handles the main menu and sub menu interaction and functionality.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function ($) {
  'use strict';

  // cached elements

  var $header = null,
      $sidebar = null,
      $anchors = null,
      $submenus = null,
      $submenuBacks = null,
      $hamburger = null,
      $close = null;

  // event listeners
  $(document).ready(initialize);

  /**
   * Take some action when the hamburger menu icon is clicked
   */
  function handleHamburgerClick(event) {
    $sidebar.toggleClass('is-active');
    $header.toggleClass('is-open');
    $anchors.removeClass('is-active');
    $submenus.removeClass('is-active');
  }

  /**
   * Do some stuff when the sub menu back button is clicked
   */
  function handleSubmenuBack(event) {
    $(event.target).closest('.block').toggleClass('is-active');
    $anchors.removeClass('is-active');
  }

  /**
   * When a user clicks a main menu item, toggle classes on both the core
   * main menu element as well as the submenu element.
   */
  function handleTopMenuClick(event) {
    event.preventDefault();

    var $el = event.currentTarget;
    var targetId = '#block-views-sub-menu-' + $el.innerHTML.toLowerCase().replace(' ', '-');
    var $targetEls = $(event.target).add(targetId);

    if ($targetEls.hasClass('is-active')) $targetEls.removeClass('is-active');else {
      $anchors.removeClass('is-active');
      $submenus.removeClass('is-active');
      $targetEls.addClass('is-active');
    }
  }

  /**
   * When the DOM is ready, cache all the relevant elements and set up
   * event handlers.
   */
  function initialize() {
    $header = $('.region-header');
    $sidebar = $('.region-sidebar-left');
    $anchors = $('#block-system-main-menu .menu a');
    $submenus = $('.region-subnav .block-views');
    $submenuBacks = $('.submenu-back');
    $hamburger = $('#hamburger');
    $close = $('#hamburger-close');

    $anchors.on('click', handleTopMenuClick);
    $submenuBacks.on('click', handleSubmenuBack);
    $hamburger.on('click', handleHamburgerClick);
    $close.on('click', handleHamburgerClick);
  }
})(jQuery);

/**
 * Abstract modal handler
 * Can style the modal however you like, the "is-active" class will be
 * applied to an activated modal window.
 * Modal opener must have a data-modal attribute with a valid jQuery selector,
 * which would be the element you'd like to activate.
 *
 * Usage:
 * <div class="open-modal" data-modal="#main-modal">Click Me</div>
 * <div id="main-modal" class="modal">
 *   <div class="copy">Hello thanks for playing</div>
 *   <div class="close close-modal">X</div>
 * </div>
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function ($) {
  'use strict';

  var $modals = null,
      $closers = null;

  $(document).ready(initialize);

  /**
   * Close the closest parent element with "modal" class
   */
  function handleClose(e) {
    var $el = $(e.target).closest('.modal');
    $el.removeClass('is-active');
    $('body').off('mousewheel', prevent);
  }

  /**
   * Open the modal given in the data-modal attribute
   */
  function handleOpen(e) {
    var $el = $(e.target),
        $modal = $($el.data('modal'));

    $modal.addClass('is-active');
    $('body').on('mousewheel', prevent);
  }

  /**
   * Grab the relevant modal elements and set up event listeners
   */
  function initialize() {
    $modals = $('.open-modal');
    $closers = $('.close-modal');

    $modals.on('click', handleOpen);
    $closers.on('click', handleClose);
  }

  /**
   * Prevents the default action for the given event.
   * In this case we're preventing scrolling when the modal is open.
   */
  function prevent(e) {
    e.preventDefault();
    e.stopPropagation();
  }
})(jQuery);

/**
 * Making object fit for images IE 11-friendly
 * @author Todd Miller <https://github.com/Toddses>
 */

(function ($) {
  'use strict';

  $(document).ready(initialize);

  function initialize() {
    objectFitImages();
  }
})(jQuery);

/**
 * Handles the search box and form functionality.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function ($) {
  'use strict';

  // elements to cache

  var $searchbox = null,
      $searchicon = null,
      $searchblock = null,
      $searchform = null;

  // event listeners
  $(document).ready(initialize);

  /**
   * Submit the form when the user clicks or taps the icon
   */
  function handleClick() {
    $searchform.submit();
  }

  /**
   * Activate and Deactivate the search icon as the user types
   * input into the search box
   */
  function handleInput(e) {
    var val = $(e.target).val();

    if (val === '') $searchblock.removeClass('is-active');else $searchblock.addClass('is-active');
  }

  /**
   * When the DOM is ready, cache all the relevant elements and set up
   * event handlers.
   */
  function initialize() {
    $searchbox = $('#block-search-form .form-text');
    $searchicon = $('#search-icon');
    $searchblock = $('#block-search-form');
    $searchform = $('#search-block-form');

    $searchbox.on('input', handleInput);
    $searchicon.on('click', handleClick);
  }
})(jQuery);

/**
 * Handles the share button functionality on single basic posts.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function () {
  'use strict';

  // cached elements

  var $wrapper = null;
  var $share = null;

  // event listeners
  document.addEventListener('DOMContentLoaded', initialize);

  /**
   * Just toggle the active class on the wrapper element.
   */
  function clickHandler() {
    $wrapper.classList.toggle('is-active');
  }

  /**
   * When the DOM is ready, cache all the relevant elements and set up
   * event handlers.
   */
  function initialize() {
    $share = document.getElementById('share-button');

    if ($share) {
      $wrapper = document.getElementById('share-wrapper');
      $share.addEventListener('click', clickHandler);
    }
  }
})();

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
        args = arguments;
    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

/*! npm.im/object-fit-images 3.2.3 */
var objectFitImages = function () {
  'use strict';

  var OFI = 'bfred-it:object-fit-images';
  var propRegex = /(object-fit|object-position)\s*:\s*([-\w\s%]+)/g;
  var testImg = typeof Image === 'undefined' ? { style: { 'object-position': 1 } } : new Image();
  var supportsObjectFit = 'object-fit' in testImg.style;
  var supportsObjectPosition = 'object-position' in testImg.style;
  var supportsOFI = 'background-size' in testImg.style;
  var supportsCurrentSrc = typeof testImg.currentSrc === 'string';
  var nativeGetAttribute = testImg.getAttribute;
  var nativeSetAttribute = testImg.setAttribute;
  var autoModeEnabled = false;

  function createPlaceholder(w, h) {
    return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" + w + "' height='" + h + "'%3E%3C/svg%3E";
  }

  function polyfillCurrentSrc(el) {
    if (el.srcset && !supportsCurrentSrc && window.picturefill) {
      var pf = window.picturefill._;
      // parse srcset with picturefill where currentSrc isn't available
      if (!el[pf.ns] || !el[pf.ns].evaled) {
        // force synchronous srcset parsing
        pf.fillImg(el, { reselect: true });
      }

      if (!el[pf.ns].curSrc) {
        // force picturefill to parse srcset
        el[pf.ns].supported = false;
        pf.fillImg(el, { reselect: true });
      }

      // retrieve parsed currentSrc, if any
      el.currentSrc = el[pf.ns].curSrc || el.src;
    }
  }

  function getStyle(el) {
    var style = getComputedStyle(el).fontFamily;
    var parsed;
    var props = {};
    while ((parsed = propRegex.exec(style)) !== null) {
      props[parsed[1]] = parsed[2];
    }
    return props;
  }

  function setPlaceholder(img, width, height) {
    // Default: fill width, no height
    var placeholder = createPlaceholder(width || 1, height || 0);

    // Only set placeholder if it's different
    if (nativeGetAttribute.call(img, 'src') !== placeholder) {
      nativeSetAttribute.call(img, 'src', placeholder);
    }
  }

  function onImageReady(img, callback) {
    // naturalWidth is only available when the image headers are loaded,
    // this loop will poll it every 100ms.
    if (img.naturalWidth) {
      callback(img);
    } else {
      setTimeout(onImageReady, 100, img, callback);
    }
  }

  function fixOne(el) {
    var style = getStyle(el);
    var ofi = el[OFI];
    style['object-fit'] = style['object-fit'] || 'fill'; // default value

    // Avoid running where unnecessary, unless OFI had already done its deed
    if (!ofi.img) {
      // fill is the default behavior so no action is necessary
      if (style['object-fit'] === 'fill') {
        return;
      }

      // Where object-fit is supported and object-position isn't (Safari < 10)
      if (!ofi.skipTest && // unless user wants to apply regardless of browser support
      supportsObjectFit && // if browser already supports object-fit
      !style['object-position'] // unless object-position is used
      ) {
          return;
        }
    }

    // keep a clone in memory while resetting the original to a blank
    if (!ofi.img) {
      ofi.img = new Image(el.width, el.height);
      ofi.img.srcset = nativeGetAttribute.call(el, "data-ofi-srcset") || el.srcset;
      ofi.img.src = nativeGetAttribute.call(el, "data-ofi-src") || el.src;

      // preserve for any future cloneNode calls
      // https://github.com/bfred-it/object-fit-images/issues/53
      nativeSetAttribute.call(el, "data-ofi-src", el.src);
      if (el.srcset) {
        nativeSetAttribute.call(el, "data-ofi-srcset", el.srcset);
      }

      setPlaceholder(el, el.naturalWidth || el.width, el.naturalHeight || el.height);

      // remove srcset because it overrides src
      if (el.srcset) {
        el.srcset = '';
      }
      try {
        keepSrcUsable(el);
      } catch (err) {
        if (window.console) {
          console.warn('https://bit.ly/ofi-old-browser');
        }
      }
    }

    polyfillCurrentSrc(ofi.img);

    el.style.backgroundImage = "url(\"" + (ofi.img.currentSrc || ofi.img.src).replace(/"/g, '\\"') + "\")";
    el.style.backgroundPosition = style['object-position'] || 'center';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.backgroundOrigin = 'content-box';

    if (/scale-down/.test(style['object-fit'])) {
      onImageReady(ofi.img, function () {
        if (ofi.img.naturalWidth > el.width || ofi.img.naturalHeight > el.height) {
          el.style.backgroundSize = 'contain';
        } else {
          el.style.backgroundSize = 'auto';
        }
      });
    } else {
      el.style.backgroundSize = style['object-fit'].replace('none', 'auto').replace('fill', '100% 100%');
    }

    onImageReady(ofi.img, function (img) {
      setPlaceholder(el, img.naturalWidth, img.naturalHeight);
    });
  }

  function keepSrcUsable(el) {
    var descriptors = {
      get: function get(prop) {
        return el[OFI].img[prop ? prop : 'src'];
      },
      set: function set(value, prop) {
        el[OFI].img[prop ? prop : 'src'] = value;
        nativeSetAttribute.call(el, "data-ofi-" + prop, value); // preserve for any future cloneNode
        fixOne(el);
        return value;
      }
    };
    Object.defineProperty(el, 'src', descriptors);
    Object.defineProperty(el, 'currentSrc', {
      get: function get() {
        return descriptors.get('currentSrc');
      }
    });
    Object.defineProperty(el, 'srcset', {
      get: function get() {
        return descriptors.get('srcset');
      },
      set: function set(ss) {
        return descriptors.set(ss, 'srcset');
      }
    });
  }

  function hijackAttributes() {
    function getOfiImageMaybe(el, name) {
      return el[OFI] && el[OFI].img && (name === 'src' || name === 'srcset') ? el[OFI].img : el;
    }
    if (!supportsObjectPosition) {
      HTMLImageElement.prototype.getAttribute = function (name) {
        return nativeGetAttribute.call(getOfiImageMaybe(this, name), name);
      };

      HTMLImageElement.prototype.setAttribute = function (name, value) {
        return nativeSetAttribute.call(getOfiImageMaybe(this, name), name, String(value));
      };
    }
  }

  function fix(imgs, opts) {
    var startAutoMode = !autoModeEnabled && !imgs;
    opts = opts || {};
    imgs = imgs || 'img';

    if (supportsObjectPosition && !opts.skipTest || !supportsOFI) {
      return false;
    }

    // use imgs as a selector or just select all images
    if (imgs === 'img') {
      imgs = document.getElementsByTagName('img');
    } else if (typeof imgs === 'string') {
      imgs = document.querySelectorAll(imgs);
    } else if (!('length' in imgs)) {
      imgs = [imgs];
    }

    // apply fix to all
    for (var i = 0; i < imgs.length; i++) {
      imgs[i][OFI] = imgs[i][OFI] || {
        skipTest: opts.skipTest
      };
      fixOne(imgs[i]);
    }

    if (startAutoMode) {
      document.body.addEventListener('load', function (e) {
        if (e.target.tagName === 'IMG') {
          fix(e.target, {
            skipTest: opts.skipTest
          });
        }
      }, true);
      autoModeEnabled = true;
      imgs = 'img'; // reset to a generic selector for watchMQ
    }

    // if requested, watch media queries for object-fit change
    if (opts.watchMQ) {
      window.addEventListener('resize', fix.bind(null, imgs, {
        skipTest: opts.skipTest
      }));
    }
  }

  fix.supportsObjectFit = supportsObjectFit;
  fix.supportsObjectPosition = supportsObjectPosition;

  hijackAttributes();

  return fix;
}();