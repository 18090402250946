/**
 * Abstract modal handler
 * Can style the modal however you like, the "is-active" class will be
 * applied to an activated modal window.
 * Modal opener must have a data-modal attribute with a valid jQuery selector,
 * which would be the element you'd like to activate.
 *
 * Usage:
 * <div class="open-modal" data-modal="#main-modal">Click Me</div>
 * <div id="main-modal" class="modal">
 *   <div class="copy">Hello thanks for playing</div>
 *   <div class="close close-modal">X</div>
 * </div>
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function ($) {
  'use strict';

  let $modals  = null,
      $closers = null;

  $(document).ready(initialize);

  /**
   * Close the closest parent element with "modal" class
   */
  function handleClose (e) {
    const $el = $(e.target).closest('.modal');
    $el.removeClass('is-active');
    $('body').off('mousewheel', prevent);
  }

  /**
   * Open the modal given in the data-modal attribute
   */
  function handleOpen (e) {
    const $el    = $(e.target),
          $modal = $($el.data('modal'));

    $modal.addClass('is-active');
    $('body').on('mousewheel', prevent);
  }

  /**
   * Grab the relevant modal elements and set up event listeners
   */
  function initialize () {
    $modals  = $('.open-modal');
    $closers = $('.close-modal');

    $modals.on('click', handleOpen);
    $closers.on('click', handleClose);
  }

  /**
   * Prevents the default action for the given event.
   * In this case we're preventing scrolling when the modal is open.
   */
  function prevent (e) {
    e.preventDefault();
    e.stopPropagation();
  }
})(jQuery);
