/**
 * Line-Clamp polyfill.
 *
 * Note: Line clamp support in webkit browsers is finicky when the
 * element moves on the page, so we're polyfilling all browsers
 * for full, comparable support across the board.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function () {
  'use strict';

  // regex to grab the last word after a whitespace
  const regex = /\W*\s(\S)*$/;

  let $homeCards  = null,
      $basicCards = null,
      cards       = [];

  /**
   * Clamp again on a debounced resize so we don't crush performance
   */
  const handleResize = debounce(() => {
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];

      // if the user resized wider, we'd lose the full copy,
      // so restore the original text so we can clamp based on that
      card.copy.textContent = card.text;

      while (card.copy.offsetHeight > card.card.clientHeight)
        card.copy.textContent = card.copy.textContent.replace(regex, '...');
    }
  }, 200);

  // event listeners
  window.addEventListener('load', initialize);
  window.addEventListener('resize', handleResize);

  /**
   * On document ready, query the relevant elements and iniitialize the clamp
   * for each one
   */
  function initialize () {
    $homeCards  = document.querySelectorAll('.home-card .title');
    $basicCards = document.querySelectorAll('.basic-card .title');

    for (let i = 0; i < $homeCards.length; i++) {
      const $card = $homeCards[i];
      // there should only be one element below the container
      const $copy = $card.querySelector('*');

      // store the elements and text so we can easily recalc on resize
      const card = {
        card: $card,
        copy: $copy,
        text: $copy.textContent
      };
      cards.push(card);

      // loop here to drop the last word until the text fits in the container
      while ($copy.offsetHeight > $card.clientHeight)
        $copy.textContent = $copy.textContent.replace(regex, '...');
    }

    for (let i = 0; i < $basicCards.length; i++) {
      const $card = $basicCards[i];
      // target the a tag below the container
      const $copy = $card.querySelector('a');

      // store the elements and text so we can easily recalc on resize
      const card = {
        card: $card,
        copy: $copy,
        text: $copy.textContent
      };
      cards.push(card);

      // loop here to drop the last word until the text fits in the container
      while ($copy.offsetHeight > $card.clientHeight)
        $copy.textContent = $copy.textContent.replace(regex, '...');
    }
  }

  /**
   * Feature detect function to determine if the browser supports line-clamp natively
   */
  function supportsLineClamp () {
    const $el = document.createElement('p');
    return typeof($el.style.webkitLineClamp) !== 'undefined';
  }
})();
