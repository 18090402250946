/**
 * Handles the main menu and sub menu interaction and functionality.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function ($) {
  'use strict';

  // cached elements
  let $header       = null,
      $sidebar      = null,
      $anchors      = null,
      $submenus     = null,
      $submenuBacks = null,
      $hamburger    = null,
      $close        = null;

  // event listeners
  $(document).ready(initialize);

  /**
   * Take some action when the hamburger menu icon is clicked
   */
  function handleHamburgerClick (event) {
    $sidebar.toggleClass('is-active');
    $header.toggleClass('is-open');
    $anchors.removeClass('is-active');
    $submenus.removeClass('is-active');
  }

  /**
   * Do some stuff when the sub menu back button is clicked
   */
  function handleSubmenuBack (event) {
    $(event.target).closest('.block').toggleClass('is-active');
    $anchors.removeClass('is-active');
  }

  /**
   * When a user clicks a main menu item, toggle classes on both the core
   * main menu element as well as the submenu element.
   */
  function handleTopMenuClick (event) {
    event.preventDefault();

    const $el        = event.currentTarget;
    const targetId   = `#block-views-sub-menu-${$el.innerHTML.toLowerCase().replace(' ', '-')}`;
    const $targetEls = $(event.target).add(targetId);

    if ($targetEls.hasClass('is-active'))
      $targetEls.removeClass('is-active');
    else {
      $anchors.removeClass('is-active');
      $submenus.removeClass('is-active');
      $targetEls.addClass('is-active');
    }
  }

  /**
   * When the DOM is ready, cache all the relevant elements and set up
   * event handlers.
   */
  function initialize () {
    $header       = $('.region-header');
    $sidebar      = $('.region-sidebar-left');
    $anchors      = $('#block-system-main-menu .menu a');
    $submenus     = $('.region-subnav .block-views');
    $submenuBacks = $('.submenu-back');
    $hamburger    = $('#hamburger');
    $close        = $('#hamburger-close');

    $anchors.on('click', handleTopMenuClick);
    $submenuBacks.on('click', handleSubmenuBack);
    $hamburger.on('click', handleHamburgerClick);
    $close.on('click', handleHamburgerClick);
  }
})(jQuery);
