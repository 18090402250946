/**
 * Handles all the functionality related to the custom filter blocks
 * on the basic and taxonomy listings.
 *
 * @author Todd Miller <todd.miller@tricomb2b.com>
 */

(function ($) {
  'use strict';

  // cached elements
  let $button  = null,
      $panel   = null,
      $filters = null,
      $boxes   = null,
      $apply   = null,
      $reset   = null,
      $form    = null;

  $(document).ready(initialize);

  /**
   * Send that form, yo!
   */
  function handleFiltersApply () {
    $form.submit();
  }

  /**
   * Deactivate all filter items and input checkboxes.
   */
  function handleFiltersReset () {
    $filters.removeClass('is-active');
    $boxes.prop('checked', false);
  }

  /**
   * When the DOM is ready, cache all the relevant elements and set up
   * click handlers.
   */
  function initialize () {
    $button  = $('#filters-button');
    $panel   = $('#filters-panel');
    $filters = $('.filter-item');
    $boxes   = $('.filter-box');
    $apply   = $('#filters-apply');
    $reset   = $('#filters-reset');
    $form    = $('#filters-form');

    $button.on('click', togglePanel);
    $filters.on('click', toggleFilterItem);
    $apply.on('click', handleFiltersApply);
    $reset.on('click', handleFiltersReset);
  }

  /**
   * Toggle the filter item class and the state of the associated input checkbox.
   */
  function toggleFilterItem (event) {
    event.stopPropagation();

    const $el  = $(event.currentTarget);
    const tid  = $el.data('tid');
    const $box = $(`#filter-box-${tid}`);

    if ($box.prop('checked'))
      $box.prop('checked', false);
    else
      $box.prop('checked', true);

    $el.toggleClass('is-active');
  }

  /**
   * Open and close the panel containing the filter items by toggling classes.
   */
  function togglePanel () {
    $panel.add($button).toggleClass('is-active');
  }
})(jQuery);
